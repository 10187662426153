import axios from "axios";
import router from "../router";

export default {
  namespaced: true,

  state: {
    userData: null,
    permissionData: null,
    menuUserData: null,
  },

  getters: {
    user: (state) => state.userData,
    permission: (state) => state.permissionData,
    menuUser: (state) => state.menuUserData,
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    },
    setPermission(state, permission) {
      state.permissionData = permission;
    },
    setMenuUser(state, menuUser) {
      state.menuUserData = menuUser;
    },
  },

  actions: {
    checkPermission({ commit }, data) {
      axios
        .get(process.env.VUE_APP_ROOT_API + "web/user-akses", {
          params: { page: data },
        })
        .then((response) => {
          localStorage.setItem("isPermission", response.data.data.isPermission);
          commit("setPermission", response.data.data.permission);
        })
        .catch(() => {
          localStorage.setItem("isPermission", false);
        });
    },
    getMenuUser({ commit }) {
      axios.get(process.env.VUE_APP_ROOT_API + "web/menu").then((response) => {
        commit("setMenuUser", response.data.data);
      });
    },
    getUserData({ commit }) {
      axios
        .get(process.env.VUE_APP_ROOT_API + "user")
        .then((response) => {
          commit("setUserData", response.data.data.user);
        })
        .catch(() => {
          localStorage.removeItem("authToken");
          localStorage.removeItem("isPermission");
          window.location.reload();
        });
    },
    sendLoginRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_ROOT_API + "login", data)
        .then((response) => {
          commit("setUserData", response.data.data.user);
          localStorage.setItem("authToken", response.data.data.token);
        });
    },
    sendLogoutRequest({ commit }) {
      axios.post(process.env.VUE_APP_ROOT_API + "logout").then(() => {
        commit("setUserData", null);
        localStorage.removeItem("authToken");
        localStorage.removeItem("isPermission");
        router.push({
          name: "login",
        });
      });
    },
  },
};
